
<template>
  <van-popup
    :value="value"
    @input="val => this.$emit('input', val)"
    round
    position="bottom"
    :style="{ height: isLandscape ? '40%': '30%' }"
  >
    <div class="tit">{{ $t("meeting.invitationMethod") }}</div>
    <div class="share">
      <div class="box">
        <div @click="copyShareUrl" class="imgBox">
          <img
            class="copyLink"
            src="../../assets/images/iconCopyLink.png"
            alt=""
          />
        </div>
        <div class="info">{{ $t("seeting.copyINfo") }}</div>
      </div>
      <!-- <div>
            <div><img src="" alt="" /></div>
            <div @click="share">分享至微信</div>
          </div> -->
    </div>
  </van-popup>
</template>

<script>
import {GetQueryString } from '../../utils/getQueryString'
export default {
  name: "Share",
  data() {
    return {};
  },
  computed: {
    isLandscape () {
      return this.$store.state.meet.isLandscape
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    value(val) {
       !val && this.$emit('close')
    }
  },
  methods: {
    hidePopup() {
      const newval = !this.value;
      this.$emit("input", newval);
    },
    copyShareUrl() {
      //url：为要设成的文本
      // 创建元素用于复制
      const aux = document.createElement("input");
      // 获取复制内容
      const url = window.location.href;
      const conferenceNo = GetQueryString('conferenceNo')
      const href = '会议欢迎您，会议链接'+' https://'+ window.location.host+'/join?conferenceNo='+conferenceNo
      // 设置元素内容
      aux.setAttribute("value", href);
      // 将元素插入页面进行调用
      document.body.appendChild(aux);
      // 复制内容
      aux.select();
      // 将内容复制到剪贴板
      window.document.execCommand("copy");
      // 删除创建元素
      document.body.removeChild(aux);
      this.hidePopup();
      this.$toast(this.$t("seeting.copySuccess"));
    },
  },
};
</script>

<style scoped  lang="less">
.tit {
  height: 88px;
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  line-height: 88px;
  margin-left: 40px;
}
.share {
  color: #333;
  margin-left: 40px;
  .imgBox {
    width: 110px;
    height: 110px;
    background: #ffffff;
    border-radius: 24px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .copyLink {
    width: 60px;
    height: 60px;
  }
  .info {
    color: #000;
    font-size: 22px;
    margin-left: 8px;
    margin-top: 14px;
  }
  .class_name {
    text-align: center;
    border-radius: 12px;
    color: #999999;
    .flex_style {
      /* display: flex; */
      text-align: center;
      .loading_style {
        width: 50px;
        height: 50px;
      }
    }
    .dialog-content {
      font-size: 18px;
      margin: 50px 0;
    }
  }
}
</style>
